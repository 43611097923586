import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyContentPreset = lazy(
  () => import("./content-preset-DaMrNJ4n.js").then((module) => ({
    default: module.ContentPreset
  }))
);
function ContentPreset({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyContentPreset, { ...props });
}
const useContentPresetContent = () => {
  return useMemo(
    () => ({
      contentPreset: {
        component: ContentPreset
      }
    }),
    []
  );
};
export {
  LazyContentPreset as ContentPreset,
  useContentPresetContent
};
